export const loadState = () => {
  try {
    const serializedData = localStorage.getItem('state_v1')
    if (serializedData === null) {
      return undefined
    }
    return (JSON.parse(serializedData))
  } catch (error) {
    return undefined
  }
}
export const saveState = (state) => {
  try {
    let serializedData = JSON.stringify(state)
    localStorage.setItem('state_v1', serializedData)
  } catch (error) {

  }
}
