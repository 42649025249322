// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";    
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAbfb4VEeSBj1qGRhJob9xym_OwRX3aYI8",
  authDomain: "chuckys-bet.firebaseapp.com",
  projectId: "chuckys-bet",
  storageBucket: "chuckys-bet.appspot.com",
  messagingSenderId: "407730210139",
  appId: "1:407730210139:web:f45d319d71ccf856ac067e",
  measurementId: "G-1F4HLBX8GS"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const db = getFirestore(app);
export default app; 