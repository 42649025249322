const Images = {
	logo: {
		logo: require('../assets/images/logo.png'),
		logo_large: require('../assets/images/logo_large.png'),
		name: require('../assets/images/name.png'),
	},
	icon: {
		up: require('../assets/images/up.png'),
		down: require('../assets/images/down.png'),
		winner: require('../assets/images/winner.png'),
	},
}

export default Images;