import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  token: false,
  user: null
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setToken: (state, action) => {
      state.token = action.payload
    },
    setUser: (state, action) => {
      state.user = action.payload
    },
    clean: (state) => {
      state.token = false
    },
  }
})

export const { setToken, setUser, clean } = authSlice.actions

export default authSlice.reducer

