import { Button, Grid, Paper, TextField } from '@mui/material'
import React from 'react'
import './LoginScreen.css';
import { Controller, useForm } from 'react-hook-form';
import Images from '../helpers/images';
import { login } from '../services/authService';
import { useDispatch } from 'react-redux';
import { setToken } from '../redux/authSlice';

const LoginScreen = () => {
  const dispatch = useDispatch();
  const { control, handleSubmit, setError } = useForm();

  const onSubmit = (data) => {
    login(data.username).then((token) => {
      dispatch(setToken(token))
    }).catch(({ message }) => {
      setError('username', { message })
    })
  }

  return (
    <div className='login-wrapper'>
      <Paper className='login-card' elevation={2}>
        <Grid container direction={'column'}>
          <Grid container item textAlign={'center'} direction={'column'} mb={5}>
            <div>
              <img alt='logo' className='login-logo' src={Images.logo.logo} />
            </div>
            <img alt='logo' className='login-logo-name' src={Images.logo.name} />
          </Grid>
          <Grid item>
            <Controller
              name="username"
              control={control}
              defaultValue=""
              rules={{
                required: "Debes agregar el nombre de usuario",
                pattern: {
                  value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  message: 'El correo electrónico no es valido'
                }
              }}
              render={({ field: { value, onChange }, fieldState: { error } }) => (
                <TextField
                  label="Usuario"
                  variant="filled"
                  fullWidth
                  // sx={{ m: 1 }}
                  value={value}
                  onChange={(e) => onChange(e.target.value.trim().toLowerCase())}
                  error={!!error}
                  helperText={error && <span> {error.message}</span>}
                />
              )}
            />
          </Grid>
          <Grid item textAlign={'center'}>
            <Button
              variant="contained"
              sx={{ m: 2, fontWeight: 'bold' }}
              onClick={handleSubmit(onSubmit)}
            >
              INGRESAR
            </Button>
          </Grid>

        </Grid>
      </Paper>
    </div>
  )
}

export default LoginScreen