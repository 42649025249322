import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import PublicRouter from "./PublicRouter";
import AuthRouter from "./AuthRouter";

const RootRouter = () => {
  const { token } = useSelector(store => store.auth)
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === '/')
      navigate(token ? "/auth" : "/")
    // eslint-disable-next-line
  }, [token])

  return (
    <Routes >
      <Route path="/*" element={<PublicRouter />} />
      <Route path="/auth/*" element={<AuthRouter />} />
    </Routes>
  )
}

export default RootRouter;