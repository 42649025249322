import { collection, doc, getDoc, getDocs, query, where } from "firebase/firestore";
import { db } from "../config/firebase";

export async function login(email) {
  const result = await getDocs(query(collection(db, "users"), where("email", "==", email)));
  if (result.empty)
    throw new Error("Usuario no encontrado");
  return result.docs[0].id;
}


export async function getUser(token) {
  const result = await getDoc(doc(db, "users", token));
  return {
    ...result.data(),
    id: result.id,};
}