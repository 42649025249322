import { Button, FormControl, Grid, IconButton, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, styled, tableCellClasses } from '@mui/material'
import './HomeScreen.css';
import React, { useEffect } from 'react'
import Images from '../helpers/images'
import { CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { setPredictionsService } from '../services/predictionsService';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { setDisabled, setMatchday, setSaved, setUserPredictions } from '../redux/gameSlice';
import { setLoading } from '../redux/uiSlice';

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: '#F0F4F8',
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontSize: 18,
    color: '#909090',
    fontWeight: 'bold',
    border: 0,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 18,
    fontWeight: 'bold',
    border: 0,

  },
}));

const HomeScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { token, user } = useSelector(store => store.auth)
  const {
    matchday,
    matchdays,
    groups,
    // games,
    users,
    // predictions,
    userPredictions,
    saved,
    disabled,
    // ranking,
  } = useSelector(store => store.game)

  useEffect(() => {
    let disabled = true
    if (saved) {
      if (user?.email === 'gdevelasco@gmail.com')
        disabled = false
      else
        disabled = moment().isBefore(moment.unix(matchday?.limit))
    } else if (matchday?.limit === undefined)
      disabled = true
    else if (moment().isAfter(moment.unix(matchday?.limit)))
      disabled = true
    else {
      disabled = userPredictions.filter(({ up, down }) => up && down).length !== Object.keys(groups).length
    }
    dispatch(setDisabled(disabled))
    // eslint-disable-next-line
  }, [userPredictions, user])

  // useEffect(() => {
  //   dispatch(setRanking(users.map(({ id, name }) => {
  //     let result = { name, total: 0 }
  //     matchdays.forEach(({ value }) => {
  //       let matchdayTotal = 0;
  //       const matchdayGames = games.filter(({ matchday, up, down }) => matchday === value && (up || down))
  //       const ups = matchdayGames.filter(({ up }) => up).map(({ id }) => id)
  //       const downs = matchdayGames.filter(({ down }) => down).map(({ id }) => id)
  //       predictions.filter(({ user, matchday }) => user === id && matchday === value).forEach(({ up, down }) => {
  //         if (up && ups.includes(up))
  //           matchdayTotal += 1
  //         if (down && downs.includes(down))
  //           matchdayTotal += 1
  //       })
  //       result[value] = matchdayTotal
  //       result.total += matchdayTotal
  //     })
  //     return result;
  //   })))
  //   // eslint-disable-next-line
  // }, [users, predictions, matchdays])

  const setPrediction = (group, game, type) => {
    const prev = userPredictions.find((item) => item.group === group);
    if (prev) {
      dispatch(setUserPredictions(userPredictions.map((item) => {
        let prediction = { ...item }
        if (prediction.group === group)
          prediction[type] = game
        if (prediction.up === prediction.down)
          prediction[type === 'up' ? 'down' : 'up'] = null
        return prediction
      })))
    } else {
      const prediction = {
        group,
        matchday: matchday.value,
        user: token
      }
      prediction[type] = game
      dispatch(setUserPredictions([...userPredictions, prediction]))
    }
  }

  const savePredictions = async () => {
    if (saved) {
      navigate('predictions')
    } else {
      dispatch(setLoading(true))
      await setPredictionsService(userPredictions)
      dispatch(setDisabled(true))
      dispatch(setSaved(true))
      dispatch(setLoading(false))
    }
  }

  return (
    <>
      <Grid container item xs={12} flex={1} direction={'column'} p={'5%'} pt={3} pb={3}>
        <Grid container item direction={'row'} mb={5}>
          <Grid mr={'5%'}>
            <h1 style={{ margin: 0 }}>Registra tu pronóstico</h1>
          </Grid>
          <Button
            variant="contained"
            // sx={{ mt: 2, mb: 2 }}
            onClick={() => savePredictions()}
            disabled={disabled}
          >
            {saved ? 'Ver Pronósticos' : 'Guardar'}
          </Button>
          {
            user?.admin && (
              <Button
                variant="outlined"
                sx={{ ml: 5 }}
                onClick={() => navigate('/auth/admin')}
              >
                Administrador
              </Button>
            )
          }
        </Grid>
        <Grid item>
          <FormControl>
            <Select
              value={matchday?.value || ''}
              onChange={(e) => dispatch(setMatchday(matchdays.find(({ value }) => value === e.target.value)))}
              size='small'
            >
              {
                matchdays.map(({ value }, index) => (
                  <MenuItem key={index} value={value}>{`Jornada ${value}`}</MenuItem>
                ))
              }
            </Select>
          </FormControl>
        </Grid>
        <Grid container item direction={'row'} spacing={3} mt={0}>
          {
            Object.entries(groups).map(([name, players], index) => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                <Paper key={index} elevation={2}>
                  <Grid container pl={3} pr={3} pb={3} overflow={'auto'}>
                    <Grid container item justifyContent={'space-between'} alignItems={'center'} minWidth={275}>
                      <Grid item>
                        <p className='group-name'>Grupo {name}</p>
                      </Grid>
                      <Grid item>
                        <img alt='up' className='icon' src={Images.icon.up} />
                        <img alt='down' className='icon' src={Images.icon.down} />
                      </Grid>
                    </Grid>
                    {
                      players.map(({ id, group, player, up, down }, index) => {
                        const upPrediction = userPredictions?.find((item) => item.up === id)
                        const downPrediction = userPredictions?.find((item) => item.down === id)
                        return (
                          <Grid container item justifyContent={'space-between'} alignItems={'center'} key={index} minWidth={275}>
                            <Grid item  >
                              <Grid container direction={'row'} alignItems={'center'} >
                                <p className='player-name'>{player}</p>
                                {up && <img alt='up' className='icon-small' src={Images.icon.up} />}
                                {down && <img alt='down' className='icon-small' src={Images.icon.down} />}
                              </Grid>
                            </Grid>
                            <Grid item>
                              <IconButton color='#B7B7B7' onClick={() => setPrediction(group, id, 'up')} disabled={saved}>
                                {upPrediction ? <CheckBox /> : <CheckBoxOutlineBlank />}
                              </IconButton>
                              <IconButton color='#B7B7B7' onClick={() => setPrediction(group, id, 'down')} disabled={saved}>
                                {downPrediction ? <CheckBox /> : <CheckBoxOutlineBlank />}
                              </IconButton>
                            </Grid>
                          </Grid>
                        )
                      })
                    }
                  </Grid>
                </Paper>
              </Grid>
            ))
          }
        </Grid>
        <Grid item mt={3} mb={2}>
          <p className='helper-text'>Únicamente puedes seleccionar al que sube y al que baja por grupo</p>
          <p className='helper-text'>Te puedes seleccionar a ti pero solo que subes, no te puedes seleccionar que bajas</p>
        </Grid>
      </Grid>
      <Grid item xs={12} flex={1}>
        <Paper elevation={2}>
          <Grid container p={'5%'} pt={3} pb={3}>
            <h1>Ranking</h1>
          </Grid>
          <Grid container p={'5%'} pb={10} pt={0}>
            <TableContainer component={Paper} sx={{ padding: 2 }}>
              <Table aria-label="simple table" size='small'>
                <TableHead>
                  <TableRow>
                    <StyledTableCell></StyledTableCell>
                    {
                      matchdays.map(({ value }, index) => (
                        <StyledTableCell key={index} align="center">J{value}</StyledTableCell>
                      ))
                    }
                    <StyledTableCell align="center">Puntos</StyledTableCell>
                    <StyledTableCell></StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    users.map(user => ({
                      ...user,
                      total: Object.entries(user).filter(([key]) => key.includes('J')).reduce((result, [key, value]) => result + value, 0)
                    })).sort((a, b) => b.total - a.total).map((row, index) => (
                      <StyledTableRow
                        key={index}
                      >
                        <StyledTableCell component="th" scope="row" style={{ whiteSpace: 'nowrap' }}>
                          {index + 1}. {row.name}
                        </StyledTableCell>
                        {
                          matchdays.map(({ value }, index) => (
                            <StyledTableCell key={index} align="center">{row[`J${value}`] || 0}</StyledTableCell>
                          ))
                        }
                        <StyledTableCell align="center">{row.total || 0}</StyledTableCell>
                        <StyledTableCell align="center" >
                          {
                            row.total !== 0 && [1, 2, 3].includes(index + 1) && <img alt='winner' className='icon-winner' src={Images.icon.winner} />
                          }
                        </StyledTableCell>
                      </StyledTableRow>
                    ))
                  }
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Paper>
      </Grid>
    </>
  )
}

export default HomeScreen