import { Grid, IconButton, Paper, } from '@mui/material'
import './AuthScreen.css';
import React, { useEffect } from 'react'
import Images from '../helpers/images'
import { Logout } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { getUser } from '../services/authService';
import { clean, setUser } from '../redux/authSlice';
import { Outlet } from 'react-router-dom';
import { getGamesSnapshot, getMatchdaysSnapshot } from '../services/gameService';
import { setGames, setGroups, setMatchday, setMatchdays, setPredictions, setSaved, setUserPredictions, setUsers } from '../redux/gameSlice';
import { getPredictionsSnapshot } from '../services/predictionsService';
import { getUsersSnapshot } from '../services/userService';

const AuthScreen = () => {
  const dispatch = useDispatch();
  const { token, user } = useSelector(store => store.auth)
  const {
    matchday,
    games,
    predictions,
  } = useSelector(store => store.game)

  useEffect(() => {
    if (token)
      Promise.all([
        getUser(token),
      ]).then(([user]) => {
        dispatch(setUser(user))
      })
    // eslint-disable-next-line
  }, [token])

  useEffect(() => {
    if (!token) return
    const unsubscribeUsers = getUsersSnapshot((list) => dispatch(setUsers(list)))
    const unsubscribetMatchdays = getMatchdaysSnapshot((list) => {
      dispatch(setMatchdays(list))
      if (!matchday)
        dispatch(setMatchday(list.find(({ current }) => current)))
    })
    return () => {
      unsubscribeUsers();
      unsubscribetMatchdays();
    }
    // eslint-disable-next-line
  }, [token])

  useEffect(() => {
    if (!matchday) return
    const unsubscribeGames = getGamesSnapshot(matchday.value, (list) => dispatch(setGames(list)))
    const unsubscribePredictions = getPredictionsSnapshot(token, matchday.value, (list) => dispatch(setPredictions(list)))
    return () => {
      unsubscribePredictions();
      unsubscribeGames();
    }
    // eslint-disable-next-line
  }, [matchday])

  useEffect(() => {
    if (matchday) {
      const userPredictions = predictions.filter(({ matchday: md, user }) => md === matchday.value && user === token)
      dispatch(setUserPredictions(userPredictions))
      dispatch(setSaved(userPredictions.length > 0))
      dispatch(setGroups(games.filter(({ matchday: md }) => md === matchday.value).reduce((result, item) => {
        const key = item.group;
        if (!result[key]) {
          result[key] = [];
        }
        result[key].push(item);
        return result;
      }, {})))
    }
    // eslint-disable-next-line
  }, [predictions, matchday, games])


  const handlerCloseSession = () => {
    dispatch(clean())
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <Paper elevation={2}>
          <Grid container flexDirection={'row'} justifyContent={'space-between'} alignContent={'center'} alignItems={'center'} p={2}>
            <Grid item >
              <img alt='logo' className='header-logo' src={Images.logo.logo_large} />
            </Grid>
            <Grid item >
              <Grid container>
                <p style={{ fontSize: 20, marginRight: 20 }}>{user?.name}</p>
                <IconButton onClick={handlerCloseSession}>
                  <Logout />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Outlet />
    </Grid>
  )
}

export default AuthScreen