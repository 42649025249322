import { collection, doc, getDoc, onSnapshot, updateDoc } from "firebase/firestore";
import { db } from "../config/firebase";

export function getUsersSnapshot(callback) {
  return onSnapshot(collection(db, "users"), (response) => {
    callback(response.docs.map((item) => ({
      ...item.data(),
      id: item.id,
    })))
  });
}

export async function setUserRankink(userId, matchday, action) {
  const user = await getDoc(doc(db, "users", userId));
  const data = user.data()
  const result = (data[`J${matchday}`] || 0) + action;
  await updateDoc(user.ref, {
    [`J${matchday}`]: result < 0 ? 0 : result
  });

}