import { Button, FormControl, Grid, MenuItem, Select, TextField } from '@mui/material'
import './AdminScreen.css';
import React, { useEffect, useState } from 'react'
import { cleanMatchdayGames, setGame, updateMatchday } from '../services/gameService';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setGroups, setMatchday } from '../redux/gameSlice';
import Group from '../components/Group';
import moment from 'moment';
import Swal from 'sweetalert2';
import { setUserRankink } from '../services/userService';
import { getMatchdayPredictions } from '../services/predictionsService';

const AdminScreen = () => {
  const navigate = useNavigate();
  const [date, setDate] = useState('');
  const dispatch = useDispatch();
  const {
    users,
    matchday,
    matchdays,
    groups,
    games,
  } = useSelector(store => store.game)
  const [predictions, setPredictions] = useState([])
  useEffect(() => {
    if (matchday) {
      setDate(moment.unix(matchday.limit).format('YYYY-MM-DDTHH:mm'))
      dispatch(setGroups(games.filter(({ matchday: md }) => md === matchday.value).reduce((result, item) => {
        const key = item.group;
        if (!result[key]) {
          result[key] = [];
        }
        result[key].push(item);
        return result;
      }, {})))
      getMatchdayPredictions(matchday.value).then((predictions) => setPredictions(predictions))
    }
    // eslint-disable-next-line
  }, [matchday?.id])

  const handlerSetPlayer = (id, value, group) => {
    setGame(id, {
      group,
      player: value,
      matchday: matchday.value
    })
  }

  const handlerUp = async (group, id, selected) => {
    if (selected) {
      await setGame(id, { up: false })
      await Promise.all(predictions.filter(({ up }) => up == id).map(async ({ user }) => {
        await setUserRankink(user, matchday.value, -1)
      }))
    } else {
      const currentUp = games.filter(({ group: g, matchday: m }) => g == group && m == matchday.value).find(({ up }) => up)
      if (currentUp) {
        await setGame(currentUp.id, { up: false })
        await Promise.all(predictions.filter(({ up }) => up == currentUp.id).map(async ({ user }) => {
          await setUserRankink(user, matchday.value, -1)
        }))
      }
      await setGame(id, { up: true, down: false })
      await Promise.all(predictions.filter(({ up }) => up == id).map(async ({ user }) => {
        await setUserRankink(user, matchday.value, 1)
      }))
    }
  }

  const handlerDown = async (group, id, selected) => {
    if (selected) {
      setGame(id, { down: false })
      await Promise.all(predictions.filter(({ down }) => down == id).map(async ({ user }) => {
        await setUserRankink(user, matchday.value, -1)
      }))
    } else {
      const currentDown = games.filter(({ group: g, matchday: m }) => g == group && m == matchday.value).find(({ down }) => down)
      if (currentDown) {
        setGame(currentDown.id, { down: false })
        await Promise.all(predictions.filter(({ down }) => down == currentDown.id).map(async ({ user }) => {
          await setUserRankink(user, matchday.value, -1)
        }))
      }
      setGame(id, { down: true, up: false })
      await Promise.all(predictions.filter(({ down }) => down == id).map(async ({ user }) => {
        await setUserRankink(user, matchday.value, 1)
      }))
    }
  }

  const cleanMatchday = () => {
    Swal.fire({
      title: "¿Seguro que quieres eliminar los registro de la jornada?",
      showCancelButton: true,
      confirmButtonText: "Borrar",
    }).then((result) => {
      if (result.isConfirmed) {
        cleanMatchdayGames(
          matchday.value,
          games.filter(({ matchday: m }) => m == matchday.value),
          predictions.filter(({ matchday: m }) => m == matchday.value),
          users
        );
      }
    });
  }

  const hanlderSetCurrent = async () => {
    const current = matchdays.find(({ current }) => current);
    await updateMatchday(current.id, {
      ...current,
      current: false
    })
    const newMatchday = {
      ...matchday,
      current: true
    }
    await updateMatchday(matchday.id, newMatchday)
    dispatch(setMatchday(newMatchday))
  }

  const handlerMatchdayLimit = async () => {
    const limit = moment(date)
    await updateMatchday(matchday.id, {
      ...matchday,
      limit: limit.toDate()
    })
    dispatch(setMatchday({
      ...matchday,
      limit: limit.unix()
    }))
  }

  return (
    <>
      <Grid container item xs={12} flex={1} direction={'column'} p={'5%'} pt={3} pb={3}>
        <Grid container item direction={'row'} mb={5}>
          <Grid mr={'5%'}>
            <h1 style={{ margin: 0 }}>Administrador</h1>
          </Grid>
          <Button
            variant="contained"
            color="error"
            // sx={{ mt: 2, mb: 2 }}
            onClick={() => cleanMatchday()}
          >
            Limpiar jornada
          </Button>
          <Button
            variant="outlined"
            sx={{ ml: 5 }}
            onClick={() => navigate('/auth')}
          >
            home
          </Button>
        </Grid>
        <Grid item>
          <FormControl>
            <Select
              value={matchday?.value || ''}
              onChange={(e) => dispatch(setMatchday(matchdays.find(({ value }) => value === e.target.value)))}
              size='small'
            >
              {
                matchdays.map(({ value }, index) => (
                  <MenuItem key={index} value={value}>{`Jornada ${value}`}</MenuItem>
                ))
              }
            </Select>
          </FormControl>
          <Button
            variant="outlined"
            sx={{ ml: 5 }}
            onClick={() => hanlderSetCurrent()}
            disabled={matchday?.current}
          >
            actual
          </Button>
          <TextField
            sx={{ ml: 5 }}
            label=""
            variant="outlined"
            value={date}
            onChange={(e) => setDate(e.target.value)}
            type="datetime-local"
            size='small'
            onBlur={handlerMatchdayLimit}
          />
        </Grid>
        <Grid container item direction={'row'} spacing={3} mt={0}>
          {
            Object.keys(groups).length === 0 ? (
              <Group
                edit={true}
                add={true}
                onSet={handlerSetPlayer}
                onUp={handlerUp}
                onDown={handlerDown}
              />
            ) : (
              Object.entries(groups).map(([name, players], index) => (
                <Group
                  key={index}
                  edit={true}
                  name={name}
                  list={players}
                  add={index === Object.keys(groups).length - 1}
                  onSet={handlerSetPlayer}
                  onUp={handlerUp}
                  onDown={handlerDown}
                />
              ))
            )
          }
        </Grid>
      </Grid >
    </>
  )
}

export default AdminScreen