import { collection, deleteDoc, doc, onSnapshot, orderBy, query, setDoc, updateDoc, where } from "firebase/firestore";
import { db } from "../config/firebase";

export function getGamesSnapshot(matchday, callback) {
  return onSnapshot(query(collection(db, "games"),
    where('matchday', '==', matchday)
  ), (response) => {
    callback(response.docs.map((item) => {
      const data = item.data()
      return {
        ...data,
        id: item.id,
      };
    }))
  });
}
export function getMatchdaysSnapshot(callback) {
  return onSnapshot(query(collection(db, "matchdays"), orderBy("value")), (response) => {
    callback(response.docs.map((item) => {
      const data = item.data()
      return {
        ...data,
        id: item.id,
        limit: data.limit?.seconds
      };
    }))
  });
}

export async function setGame(id, data) {
  if (id) {
    const docRef = doc(db, "games", id);
    await updateDoc(docRef, data);
  } else {
    const docRef = doc(collection(db, "games"));
    await setDoc(docRef, data);
  }
}

export async function cleanMatchdayGames(matchday, games, predictions, users) {
  await Promise.all(predictions.map((item) => deleteDoc(doc(db, "predictions", item.id))));
  await Promise.all(games.map((item) => deleteDoc(doc(db, "games", item.id))));
  await Promise.all(users.map((item) => updateDoc(doc(db, "users", item.id), {
    [`J${matchday}`]: 0
  })));
}

export async function updateMatchday(id, data) {
  const docRef = doc(db, "matchdays", id);
  await updateDoc(docRef, data);
}