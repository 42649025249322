import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { loadState, saveState } from "../helpers/storeState";
import uiReducer from "./uiSlice";
import authReducer from "./authSlice";
import gameReducer from "./gameSlice";

const appReducer = combineReducers({
  ui: uiReducer,
  auth: authReducer,
  game: gameReducer,
});

const store = configureStore({
  preloadedState: loadState(),
  reducer: (state, action) => {
    if (action.type === 'auth/clean') {
      state = undefined;
    }
    return appReducer(state, action);
  }
})

store.subscribe(() => {
  saveState(store.getState())
})

export default store