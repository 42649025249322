import { Button, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, styled, tableCellClasses } from '@mui/material'
import './PredictionsScreen.css';
import React, { useEffect, useState } from 'react'
import Images from '../helpers/images'
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getMatchdayPredictions } from '../services/predictionsService';

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: '#F0F4F8',
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontSize: 18,
    color: '#909090',
    fontWeight: 'bold',
    border: 0,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 18,
    fontWeight: 'bold',
    border: 0,

  },
}));

const PredictionsScreen = () => {
  const navigate = useNavigate();
  const {
    matchday,
    groups,
    users,
    games,
  } = useSelector(store => store.game)

  const [list, setList] = useState([])

  useEffect(() => {
    getMatchdayPredictions(matchday.value).then((predictions) => {
      setList(users.map(user => {
        let newUser = { ...user }
        predictions.filter(({ matchday: md, user: uid }) => md === matchday.value && uid === user.id).forEach(({ group, up, down }) => {
          up = games.find(({ id }) => id === up)
          down = games.find(({ id }) => id === down)
          newUser[group] = { up, down }
        });
        return newUser
      }))
    })
    // eslint-disable-next-line
  }, [matchday])

  return (
    <Grid container>
      <Grid item xs={12} flex={1} mt={1}>
        <Paper elevation={2}>
          <Grid container item direction={'row'} p={'5%'} pt={3} pb={3}>
            <Grid mr={5}>
              <h1>Pronósticos - Jornada {matchday.value}</h1>
            </Grid>
            <Button
              sx={{ mt: 2, mb: 2 }}
              variant='outlined'
              onClick={() => navigate(-1)}
            >
              Regresar
            </Button>
          </Grid>
          <Grid container p={'5%'} pt={3} pb={3}>
            <TableContainer component={Paper} sx={{ padding: 2, overflow: 'auto' }}>
              <Table aria-label="simple table" size='small'>
                <TableHead>
                  <TableRow>
                    <StyledTableCell></StyledTableCell>
                    {
                      Object.entries(groups).map(([group, list], index) => {
                        const up = list.find(({ up }) => up)
                        const down = list.find(({ down }) => down)
                        return (
                          <StyledTableCell key={index} align="center">
                            <Grid container pl={2} pr={2} sx={{ minWidth: 250 }}>
                              <Grid item xs={3} >
                                <img alt='up' className='icon-up' src={Images.icon.up} />
                                <div>{up?.player}</div>
                              </Grid>
                              <Grid item xs={4}>
                                <Typography
                                  fontWeight={'bold'}
                                  whiteSpace={'nowrap'}
                                  sx={{ textTransform: 'uppercase' }}
                                > Grupo {group}</Typography>
                              </Grid>
                              <Grid item xs={3}>
                                <img alt='down' className='icon-down' src={Images.icon.down} />
                                <div>{down?.player}</div>
                              </Grid>
                            </Grid>
                          </StyledTableCell>
                        )
                      })
                    }
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    list.map((user, index) => (
                      <StyledTableRow
                        key={index}
                      >
                        <StyledTableCell component="th" scope="row">
                          <Typography fontWeight={'bold'} whiteSpace={'nowrap'}> {index + 1}. {user.name} </Typography>
                        </StyledTableCell>
                        {
                          Object.entries(groups).map(([group], index) => (
                            <StyledTableCell key={index} align="center" >
                              <Grid container pl={2} pr={2} sx={{ minWidth: 250 }}>
                                <Grid item xs={3}>
                                  <Typography className={user[group]?.up?.up ? 'right' : ''}>{user[group]?.up?.player}</Typography>
                                </Grid>
                                <Grid item xs={4}>
                                </Grid>
                                <Grid item xs={3}>
                                  <Typography className={user[group]?.down?.down ? 'right' : ''}>{user[group]?.down?.player}</Typography>
                                </Grid>
                              </Grid>
                            </StyledTableCell>
                          ))
                        }
                      </StyledTableRow>
                    ))
                  }
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  )
}

export default PredictionsScreen