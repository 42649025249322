import { Route, Routes } from "react-router-dom";
import LoginScreen from "../screens/LoginScreen";

const PublicRouter = () => {
  return (
    <Routes >
      <Route path="/" element={<LoginScreen />} />
    </Routes>
  )
}

export default PublicRouter;