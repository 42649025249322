import { Route, Routes, useNavigate } from "react-router-dom";
import HomeScreen from "../screens/HomeScreen";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import AuthScreen from "../screens/AuthScreen";
import PredictionsScreen from "../screens/PredictionsScreen";
import AdminScreen from "../screens/AdminScreen";

const AuthRouter = () => {
  const { token } = useSelector(store => store.auth)
  const navigate = useNavigate();

  useEffect(() => {
    if (!token) navigate('/')
    // eslint-disable-next-line
  }, [token])

  return (
    <Routes>
      <Route path="/" element={<AuthScreen />} >
        <Route index element={<HomeScreen />} />
        <Route path="predictions" element={<PredictionsScreen />} />
        <Route path="admin" element={<AdminScreen />} />
      </Route>
    </Routes>
  )
}

export default AuthRouter;