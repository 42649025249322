import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  matchday: null,
  groups: [],
  matchdays: [],
  users: [],
  predictions: [],
  userPredictions: [],
  games: [],
  ranking: [],
  saved: false,
  disabled: true,
}

export const gameSlice = createSlice({
  name: 'game',
  initialState,
  reducers: {
    setMatchday: (state, action) => {
      state.matchday = action.payload
    },
    setGroups: (state, action) => {
      state.groups = action.payload
    },
    setMatchdays: (state, action) => {
      state.matchdays = action.payload
    },
    setUsers: (state, action) => {
      state.users = action.payload
    },
    setPredictions: (state, action) => {
      state.predictions = action.payload
    },
    setUserPredictions: (state, action) => {
      state.userPredictions = action.payload
    },
    setGames: (state, action) => {
      state.games = action.payload
    },
    setRanking: (state, action) => {
      state.ranking = action.payload
    },

    setSaved: (state, action) => {
      state.saved = action.payload
    },
    setDisabled: (state, action) => {
      state.disabled = action.payload
    },
  }
})

export const {
  setMatchday,
  setGroups,
  setMatchdays,
  setUsers,
  setPredictions,
  setUserPredictions,
  setGames,
  setRanking,
  setSaved,
  setDisabled,
} = gameSlice.actions

export default gameSlice.reducer

