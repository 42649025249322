import { collection, doc, getDocs, onSnapshot, query, setDoc, where } from "firebase/firestore";
import { db } from "../config/firebase";

export function getPredictionsSnapshot(user, matchday, callback) {
  return onSnapshot(query(collection(db, "predictions"),
    where('user', '==', user),
    where('matchday', '==', matchday)
  ), (response) => {
    callback(response.docs.map((item) => ({
      ...item.data(),
      id: item.id,
    })))
  });
}

export async function getMatchdayPredictions(matchday) {
  const result = await getDocs(collection(db, "predictions"), where('matchday', '==', matchday));
  return result.docs.map((item) => ({ ...item.data(), id: item.id }));
}

export async function setPredictionsService(list) {
  return Promise.all(list.forEach(async (item) => {
    const docRef = item.id ? doc(db, "predictions", item.id) : doc(collection(db, "predictions"));
    await setDoc(docRef, item);
  }));
}
