import { Add, CheckBox, CheckBoxOutlineBlank, Remove } from '@mui/icons-material'
import { Grid, IconButton, Paper, TextField } from '@mui/material'
import React, { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import Images from '../helpers/images'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setGroups } from '../redux/gameSlice'

const Group = ({ name, list = [], edit = false, disabled, onUp, onDown, onSet, add }) => {
  const dispatch = useDispatch();
  const [players, setPlayers] = useState(list);
  const { groups } = useSelector(store => store.game)
  const { control, watch, setValue } = useForm();
  const { groupName } = watch()

  useEffect(() => {
    setValue('groupName', name === 'temp' ? '' : name)
    // eslint-disable-next-line
  }, [name])

  useEffect(() => {
    list.forEach(({ player }, index) => {
      setValue(`player_${index || 0}`, player)
    });
    setPlayers(list)
    // eslint-disable-next-line
  }, [list])

  const handlerAddGroup = () => {
    dispatch(setGroups({ ...groups, temp: [] }))
  }

  const handlerAdd = () => {
    setPlayers([...players, { player: '', up: false, down: false }])
  }

  const handlerRemove = (id, index) => {
    if (id) {
      setPlayers(players.map((player) => {
        if (player.id === id) {
          player.remove = true
        }
        return player
      }))
    } else {
      setPlayers(players.filter((player, i) => i !== index))
    }
  }

  const handlerSetPlayer = (id, value) => {
    onSet(id, value, groupName)
  }

  return (
    <Grid container item xs={12} sm={6} md={4} lg={3} direction={'row'} wrap='nowrap'>
      <Grid item >
        <Paper elevation={2}>
          <Grid container pl={3} pr={3} pb={3} pt={3} overflow={'auto'}>
            <Grid container item justifyContent={'space-between'} alignItems={'center'} minWidth={275} mb={2}>
              <Grid item flex={2}>
                <Controller
                  name="groupName"

                  control={control}
                  defaultValue=""
                  rules={{
                    required: "Debes agregar el grupo",
                  }}
                  render={({ field: { value, onChange }, fieldState: { error } }) => (
                    <TextField
                      label="Grupo"
                      variant="outlined"
                      // fullWidth
                      value={value}
                      onChange={(e) => onChange(e.target.value)}
                      error={!!error}
                      helperText={error && <span> {error.message}</span>}
                    />
                  )}
                />
              </Grid>
              <Grid item flex={1}>
                <img alt='up' className='icon' src={Images.icon.up} />
                <img alt='down' className='icon' src={Images.icon.down} />
              </Grid>
            </Grid>
            {
              players.filter(item => !item.remove).map(({ id, group, up, down }, index) => (
                <Grid container item justifyContent={'space-between'} alignItems={'center'} key={index} minWidth={275} mb={3}>
                  <Grid container item flex={2}>
                    <Grid container item mb={2} wrap='nowrap' marginBottom={0}>
                      <IconButton color='error' onClick={() => handlerRemove(id, index)}>
                        <Remove />
                      </IconButton>
                      <Controller
                        name={`player_${index}`}
                        control={control}
                        defaultValue=""
                        rules={{
                          required: "Debes agregar el nombre del jugador",
                        }}
                        render={({ field: { value, onChange }, fieldState: { error } }) => (
                          <TextField
                            label={`Jugador ${index + 1}`}
                            variant="outlined"
                            // fullWidth
                            value={value}
                            disabled={groupName === ''}
                            onChange={(e) => onChange(e.target.value)}
                            error={!!error}
                            helperText={error && <span> {error.message}</span>}
                            onBlur={() => handlerSetPlayer(id, value)}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                  <Grid container item flex={1} height='100%' alignContent={'center'} alignItems={'center'} justifyContent={'center'} justifyItems={'center'}>
                    <IconButton color='#B7B7B7' onClick={() => onUp(group, id, !!up)} disabled={!id}>
                      {up ? <CheckBox /> : <CheckBoxOutlineBlank />}
                    </IconButton>
                    <IconButton color='#B7B7B7' onClick={() => onDown(group, id, !!down)} disabled={!id}>
                      {down ? <CheckBox /> : <CheckBoxOutlineBlank />}
                    </IconButton>
                  </Grid>
                </Grid>
              ))
            }
            <Grid container item justifyContent={'center'} alignItems={'center'} >
              <IconButton color='primary' onClick={() => handlerAdd()}>
                <Add />
              </IconButton>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      {
        add && (
          <Grid item height={'100%'} justifyContent={'center'} alignContent={'center'} alignItems={'center'} justifyItems={'center'}>
            <IconButton color='primary' onClick={() => handlerAddGroup()} >
              <Add />
            </IconButton>
          </Grid>
        )
      }
    </Grid>
  )
}

export default Group